import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import iett1 from "../../assets/img/iett1.png"
import iett2 from "../../assets/img/iett2.png"

export default function IETTProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="İETT Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={iett1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("iett")}
            <br />
            <span>{t("project-sector")}</span>: {t("tasimacilik")}
            <br />
            <span>{t("project-subject")}</span>: {t("modern-hat-sergisi")}
            <br />
            <span>{t("project-artwork")}</span>: {t("gizli-koleksiyon")}
          </div>
          <img src={iett2} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
